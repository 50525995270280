import { NgModule } from '@angular/core';
import { ToastAlertsModule } from 'app/toast-alerts/toast-alerts.module';
import { CompatToastAlertsComponent } from './compat-toast-alerts.component';

@NgModule({
  imports: [ToastAlertsModule],
  declarations: [CompatToastAlertsComponent],
  entryComponents: [CompatToastAlertsComponent],
})
export class CompatToastAlertsModule {}
